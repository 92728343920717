/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, actionTypes } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import firebase from "firebase";
import { useState } from "react";

export function Routes() {


  const dispatch = useDispatch()
  const [authorized, setauthorized] = useState()


  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken != null,
    }),
    shallowEqual
  );



  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({
          type: actionTypes.Login,
          payload: {
            authToken: user.refreshToken
          }
        })
      }
    })
  }, [])






  return (
    <Switch>
      {!localStorage.getItem("authToken") || authorized ? (
        //Render auth page when user at `/auth` and not authorized.
        <Route>
          <AuthPage />
        </Route>
      ) : localStorage.getItem("authToken") || authorized ? (
        <Redirect from="/auth" to="/dashboard" />
      ) : null}

      <Route path="/error" component={ErrorsPage} />

      {localStorage.getItem("authToken") || authorized ? (
        <Layout>
          {
            isAuthorized &&
            <BasePage />
          }
        </Layout>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </Switch>
  );
}
