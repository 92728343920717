
import * as firebase from "firebase";

// export const firebaseConfig = {
//     apiKey: "AIzaSyBT1qjFw3McGFXetwNxObiE6fvK-BnjdPg",
//     authDomain: "balaskar-a5905.firebaseapp.com",
//     projectId: "balaskar-a5905",
//     storageBucket: "balaskar-a5905.appspot.com",
//     messagingSenderId: "734614640243",
//     appId: "1:734614640243:web:70003a4b5d5253eeddfc06"
// };


export const firebaseConfig = {
  apiKey: "AIzaSyBKa77DsOtNM8rFHzrZnYNg5OsHAZy-yvg",
  authDomain: "dash-app-b6497.firebaseapp.com",
  databaseURL: "https://dash-app-b6497-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dash-app-b6497",
  storageBucket: "dash-app-b6497.appspot.com",
  messagingSenderId: "479401387259",
  appId: "1:479401387259:web:a032bd87c3d7d122cc7c5e"
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}


  export const firebaseAuth = firebase.auth();

  export const googleAuthProvider  = new firebase.auth.GoogleAuthProvider()